<template>
    <div>
        <div class="filters-name mb-2">
            {{ filter.display_name }}
        </div>
        <v-text-field
            v-if="showSearch"
            v-model="search"
            :placeholder="`Filter ${filter.display_name.toLowerCase()}...`"
            class="styled-field mb-3"
            clearable />
        <div
            v-for="item in displayedItems"
            :key="item.id"
            class="mb-2">
            <styled-checkbox
                :value="item.id"
                :label="fbToMeta(item.display_name)"
                :checked="setChecked(item)"
                :inline="true"
                :square="true"
                :disabled="loading"
                @change="selectTag($event, isCustom)" />
        </div>
        <a
            v-if="moreCount > 0 && !hideShowMore"
            class="filter-link"
            href="#"
            @click.prevent="showMore = !showMore">
            {{ showMore ? 'Show less' : `Show ${moreCount} more` }}
        </a>
        <span
            v-if="search && displayedItems == 0"
            class="no-results">No matches found...</span>
    </div>
</template>

<script>
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        StyledCheckbox
    },
    props: {
        filter: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        activeFilters: {
            type: Array,
            required: true
        },
        displayCount: {
            type: Number,
            default: 5,
        },
        showSearch: {
            type: Boolean,
            default: false
        },
        isCustom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: '',
            showMore: false,
            hideShowMore: false,
            fbToMeta
        };
    },
    computed: {
        displayedItems() {
            if (this.search) {
                return this.filter.tags.data.filter(item => (
                    item.display_name.toLowerCase().includes(this.search.toLowerCase())
                ));
            }
            if (!this.showMore && (this.filter.tags.data.length > this.displayCount)) {
                return this.filter.tags.data.slice(0, this.displayCount);
            }
            return this.filter.tags.data;
        },
        moreCount() {
            return this.filter.tags.data.length - this.displayCount;
        }
    },
    watch: {
        search(value) {
            this.hideShowMore = value ? true : false;
        }
    },
    methods: {
        setChecked(tag) {
            if (!this.activeFilters.includes(tag)) {
                return false;
            } else {
                return true;
            }
        },
        selectTag(e, isCustom) {
            this.$emit('select-tag', e, isCustom);
        }
    }
};
</script>

<style lang="scss" scoped>
.filters-name,
.no-results {
    color: #00A4EB;
}
</style>