const cutTitleLength = (titleHeight, titleText) => {
    const shortenedTitle = titleText.slice(0, -4);
    let title = titleText;
    //making sure we don't have more than 2 lines
    if (titleHeight > 50) {
        const dots = shortenedTitle.substring(shortenedTitle.length - 3);
        title = dots === '...' ? shortenedTitle : shortenedTitle.concat('...');
        return {
            ok: false,
            title
        }
    }
    return {
        ok: true,
        title
    }
}

export default cutTitleLength;