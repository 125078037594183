<template>
    <div v-if="!authorIsHidden && !userIsLoading">
        <span class="author-title">Author:</span>
        <span
            v-if="authorName"
            class="author">{{ authorName }}</span>
        <span
            v-else
            class="author">BuyerBridge</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { BB_USER_ID } from '@/helpers/globals';

export default {
    props: {
        author: {
            type: String,
            default: ''
        },
        authorId: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            authorName: '',
            userIsLoading: false
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
        }),
        authorIsHidden() {
            if (this.agency.configuration?.has_white_label_access && this.authorId == BB_USER_ID) {
                return true;
            } else {
                return false;
            }
        },
    },
    mounted() {
        if (this.author) {
            this.authorName = this.author;
        }
        if (this.authorId && !this.author) {
            this.getAuthor(this.authorId);
        }
    },
    methods: {
        async getAuthor(id) {
            try {
                this.userIsLoading = true;
                const user = await this.$apiRepository.getUser(id);
                this.authorName = user.data.data.name;
            } catch (error) {
                console.error(error);
            } finally {
                this.userIsLoading = false;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.author-title {
    color: $gray-dark;
}
.author {
    color: $blue-primary;
    text-decoration: underline;
    margin-left: 5px;
}
</style>
