<template>
    <div class="adslib__body">
        <!-- FILTERS -->
        <div class="filters">
            <div class="filters__top">
                <div class="filters__title">
                    Attributes
                </div>
                <div
                    v-if="activeTags.length || activeCustomFilters.length"
                    class="filters__clear"
                    @click="clearAll">
                    Clear all
                </div>
            </div>
            <div
                v-for="customFilter in customFilters"
                :key="customFilter.id"
                class="filters__block">
                <filter-list
                    :filter="customFilter"
                    :loading="loading"
                    :is-custom="true"
                    :active-filters="activeCustomFilters"
                    @select-tag="selectTag" />
            </div>
            <div
                v-for="tag in tags"
                :key="tag.id"
                class="filters__block">
                <filter-list
                    :filter="tag"
                    :loading="loading"
                    :active-filters="activeTags"
                    :show-search="tag.display_name === 'Brand'"
                    @select-tag="selectTag" />
            </div>
        </div>
        <!-- END FILTERS -->

        <div class="adslib__main">
            <!-- sorting items -->
            <div class="adslib__search-wrapper">
                <div class="adslib__search">
                    <input
                        v-model="searchTerm"
                        type="text"
                        placeholder="Search ads..."
                        @input="onSearch">
                </div>
                <div class="adslib__sorting">
                    <div class="adslib__count">
                        <span>Total Ads: {{ total }}</span>
                        <span
                            class="select-all"
                            @click="selectAll">Select All</span>
                    </div>
                    <div class="adslib__sort">
                        <span>Sort:</span>
                        <v-select
                            v-model="sort"
                            :items="sortItems"
                            class="adslib__select adslib__select-sort"
                            @change="changeSorting" />
                    </div>
                </div>
            </div>
            <div class="adslib__filtered">
                <div class="adslib__filters">
                    <div
                        v-if="activeTags.length || activeCustomFilters.length"
                        class="adslib__title">
                        Filtered by:
                    </div>
                    <div class="adslib__applied">
                        <div
                            v-for="activeCustomFilter in activeCustomFilters"
                            :key="activeCustomFilter.id"
                            class="adslib__single">
                            <span>{{ activeCustomFilter.display_name }}</span>
                            <div @click="removeCustomFilter(activeCustomFilter)">
                                <icon
                                    name="close"
                                    color="#8f9ea6"
                                    size="10"
                                    @click="removeCustomFilter(activeCustomFilter)" />
                            </div>
                        </div>
                        <div
                            v-for="activeTag in activeTags"
                            :key="activeTag.id"
                            class="adslib__single">
                            <span>{{ fbToMeta(activeTag.display_name) }}</span>
                            <div @click="removeTag(activeTag)">
                                <icon
                                    name="close"
                                    color="#8f9ea6"
                                    size="10"
                                    @click="removeTag(activeTag)" />
                            </div>
                        </div>
                        <div
                            v-if="activeTags.length || activeCustomFilters.length"
                            class="adslib__clear"
                            @click="clearAll">
                            Clear all
                        </div>
                    </div>
                </div>
                <div
                    v-if="selectedAds.length"
                    v-outside-click="hideDeploy"
                    class="adslib__selected">
                    <div
                        class="adslib__button"
                        @click="deployActive = true">
                        <div class="adslib__circle">
                            {{ selectedAds.length }}
                        </div>
                        <span class="mr-3">DEPLOY ADS</span>
                        <div class="adslib__collapse">
                            <icon
                                name="collapse-all"
                                color="white"
                                size="15" />
                        </div>
                    </div>
                    <div
                        v-if="deployActive"
                        class="adslib__deploy">
                        <div
                            v-for="item in selectedAds"
                            :key="item.id"
                            class="adslib__item">
                            <div class="adslib__mediaholder">
                                <div class="adslib__media">
                                    <img
                                        v-if="adType(item) == 'image'"
                                        :src="adUrl(item)"
                                        alt="">
                                    <video
                                        v-if="adType(item) == 'video'"
                                        muted
                                        autoplay
                                        loop>
                                        <source
                                            :src="adUrl(item)"
                                            type="video/mp4">
                                    </video>
                                </div>
                                <div class="adslib__desc">
                                    {{ item.display_name }}
                                </div>
                            </div>
                            <div
                                class="adslib__close"
                                @click.stop="removeAndUnselect(item.id)">
                                <icon
                                    name="close"
                                    color="white"
                                    size="10" />
                            </div>
                        </div>
                        <div
                            class="adslib__complete"
                            @click="deployAds()">
                            DEPLOY
                        </div>
                    </div>
                </div>
            </div>
            <!-- end sorting items -->

            <!-- ADS -->
            <div
                :key="cardKey"
                class="card-wrapper">
                <div
                    v-if="notice && selectedAds.length"
                    class="select-all-notice">
                    Selected {{ selectedAds.length }} displayed ads of {{ total }} total. Continue scrolling to select more.
                </div>
                <transition-group name="cards">
                    <ad-card
                        v-for="ad in ads"
                        :key="ad.id"
                        :ad-data="ad"
                        :is-selected="ad.selected"
                        :duplicating-loading="duplicatingLoading"
                        @duplicate-ad="duplicateAd"
                        @select-ad="selectAd"
                        @remove-ad="removeAd"
                        @edit-ad="editAd"
                        @show-preview="showPreview" />
                </transition-group>
            </div>
            <div v-if="loading">
                <loader />
            </div>
            <div
                v-if="!loading"
                v-intersect="getAds" />
            <h2
                v-if="!loading && ads.length == 0"
                class="mx-5 my-5">
                Sorry, but nothing matched your search criteria...
            </h2>
            <!-- END ADS -->
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import AdCard from '@/components/ads-library/AdCard';
import FilterList from '@/components/ads-library/FilterList';
import Loader from '@/components/globals/Loader.vue';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import EventBus from '@/event-bus';
import { format } from 'date-fns';
import sleep from '@/helpers/sleep';
import { PLATFORM_TAG_GROUP_ID, MEDIA_FORMAT_TAG_GROUP_ID, BRANDS_TAG_GROUP_ID } from '@/helpers/globals';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        Icon,
        AdCard,
        FilterList,
        Loader
    },
    data() {
        return {
            page: 1,
            searchTerm: '',
            sort: 'Newest',
            sortItems: ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'],
            notice: false,
            loading: true,
            currentAdId: {},
            ads: [],
            tags: [],
            total: 0,
            activeTags: [],
            customFilters: [],
            activeCustomFilters: [],
            filtersList: [],
            tagsList: [],
            cardKey: 0,
            selectedAds: [],
            deployActive: false,
            duplicatedAdIds: [],
            duplicatingLoading: false,
            fbToMeta
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.user,
            currentAgency: (state) => state.agency.currentAgency,
            currentAgencyId: (state) => state.agency.currentAgency.id,
            dealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
            adsInStudio: state => state.adStudio.ads.length,
        }),
    },
    watch: {
        currentAgencyId() {
            this.reset();
            this.getAds();
        },
        activeTags() {
            this.reset();
            this.getAds();
        },
        activeCustomFilters() {
            this.reset();
            this.getAds();
        },
        adsInStudio() {
            if (this.adsInStudio == 0) {
                // adding a small delay to make sure that new ads appeared in DB
                setTimeout(() => {
                    this.reset();
                    this.getAds();
                }, 2500);
            }
        }
    },
    async mounted() {
        this.$title = 'Ads Library';
        this.getTagsByTagGroup(PLATFORM_TAG_GROUP_ID);
        this.getTagsByTagGroup(MEDIA_FORMAT_TAG_GROUP_ID);
        this.getTagsByTagGroup(BRANDS_TAG_GROUP_ID);
        const rawFilters = [{
            display_name: 'Ownership',
            tags: [
                { display_name: 'My Ads' },
                { display_name: `${this.currentAgency.name} Ads` },
                { display_name: 'BuyerBridge Ads' }
            ]
        }];
        for (const rawFilter of rawFilters) {
            const customFilter = await this.createFilterStructure(rawFilter);
            customFilter.tags.data.forEach(filter => {
                this.filtersList.push(filter);
                this.activeCustomFilters.push(filter);
            });
            this.customFilters.push(customFilter);
        }
        this.loading = false;
    },
    methods: {
        async getUUID() {
            try {
                const response = await this.$http.get('/uuid/');
                return response.data.uuid4;
            } catch(error) {
                console.log(error);
            }
        },
        async createFilterStructure(filter) {
            const data = [];
            for (const tag of filter.tags) {
                const uuid = await this.getUUID();
                const tagWithUUID = {
                    id: uuid,
                    display_name: tag.display_name
                };
                data.push(tagWithUUID);
            }
            const uuid = await this.getUUID();
            const filterStructure = {
                id: uuid,
                display_name: filter.display_name,
                tags: {
                    data
                }
            };
            return filterStructure;
        },
        adType(item) {
            return item.full_configuration?.all_platforms?.creatives[0].assets[0].asset_type.name ?? item.configuration?.all_platforms?.creatives[0].assets[0].asset_type.name;
        },
        adUrl(item) {
            return item.full_configuration?.all_platforms?.creatives[0].assets[0].url ?? item.configuration?.all_platforms?.creatives[0].assets[0].url;
        },
        changeSorting() {
            this.reset();
            this.getAds();
        },
        hideDeploy() {
            this.deployActive = false;
        },
        reset() {
            this.notice = false;
            this.page = 1;
            this.ads = [];
            this.duplicatedAdIds = [];
        },
        selectAd(id) {
            const currentAd = this.ads.find(ad => ad.id == id);
            if (currentAd) {
                currentAd.selected = true;
            }
            this.selectedAds.push(currentAd);
        },
        removeAndUnselect(id) {
            this.removeAd(id);
            const currentAd = this.ads.find(ad => ad.id == id);
            if (currentAd) {
                currentAd.selected = false;
            }
            this.cardKey += 1;
        },
        removeAd(id) {
            const currentAd = this.selectedAds.find(ad => ad.id == id);
            const adIndex = this.selectedAds.indexOf(currentAd);
            if (adIndex > -1) {
                this.selectedAds.splice(adIndex, 1);
            }
        },
        selectTag(e, isCustom) {
            if (isCustom) {
                const currentFilter = this.filtersList.find(f => f.id == e.target.value);
                if (e.target.checked) {
                    this.activeCustomFilters.push(currentFilter);
                } else {
                    this.removeCustomFilter(currentFilter);
                }
            } else {
                const currentTag = this.tagsList.find(tag => tag.id == e.target.value);
                if (e.target.checked) {
                    this.activeTags.push(currentTag);
                } else {
                    this.removeTag(currentTag);
                }
            }
        },
        removeTag(tag) {
            const tagIndex = this.activeTags.indexOf(tag);
            if (tagIndex > -1) {
                this.activeTags.splice(tagIndex, 1);
            }
        },
        removeCustomFilter(filter) {
            const filterIndex = this.activeCustomFilters.indexOf(filter);
            if (filterIndex > -1) {
                this.activeCustomFilters.splice(filterIndex, 1);
            }
        },
        clearAll() {
            this.searchTerm = '';
            this.activeTags = [];
            this.activeCustomFilters = [];
            this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
            this.sort = 'Newest';
        },
        onSearch: debounce(function() {
            this.reset();
            // adding relevance when we have something in search field
            if (this.searchTerm != '') {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)', 'Relevance'];
                this.sort = 'Relevance';
            } else {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
                this.sort = 'Newest';
            }
            this.getAds();
        }, 500),
        removeSomeTags(tagGroup) {
            const filteredTags = [];
            tagGroup.tags.data.forEach(tag => {
                filteredTags.push(tag);
            });
            tagGroup.tags.data = filteredTags;
            return tagGroup;
        },
        async getTagsByTagGroup(tagGroupID) {
            try {
                const response = await this.$http.get(`tag_groups/${tagGroupID}`, { with_relationships: 'tag_groups.tags' });
                const tagsData = [];
                const filteredTags = this.removeSomeTags(response.data.data);
                this.tags.push(filteredTags);
                tagsData.push(filteredTags);
                tagsData.forEach(tag => {
                    tag.tags.data.forEach(t => {
                        this.tagsList.push(t);
                    });
                });
            } catch(error) {
                console.log(error);
            }
        },
        async getAds() {
            if (!this.page) {
                return;
            }
            this.loading = true;
            let direction = null;
            let dateField = 'created_at.date.keyword';

            // main sorting
            if (this.sort == 'Newest') {
                direction = 'DESC';
            } else if (this.sort == 'Oldest') {
                direction = 'ASC';
            } else if (this.sort == 'Last Updated') {
                direction = 'DESC';
                dateField = 'updated_at.date.keyword';
            } else if (this.sort == 'Alphabetical (A-Z)') {
                direction = 'ASC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Alphabetical (Z-A)') {
                direction = 'DESC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Relevance') {
                direction = 'DESC';
                dateField = '_score';
            }

            // sorting by tags
            const tagData = {};
            this.tags.forEach(tagGroup => {
                const tagGroupName = {
                    [tagGroup.name]: []
                };
                Object.assign(tagData, tagGroupName);
            });
            this.activeTags.forEach(tag => {
                const tagGroupName = this.tags.find(t => t.id == tag.tag_group_id);
                tagData[tagGroupName.name].push(tag.id);
            });
            // removing empty tags... because back-end is not working with empty request...
            for (let key in tagData) {
                if (tagData[key].length == 0) {
                    delete tagData[key];
                }
            }

            // sorting by custom filters
            let userId = null;
            let agencyId = null;
            let ownership = null;
            this.activeCustomFilters.forEach(filter => {
                if (filter.display_name === 'My Ads') {
                    userId = this.currentUser.id;
                }
                if (filter.display_name === `${this.currentAgency.name} Ads`) {
                    agencyId = this.currentAgencyId;
                }
                if (filter.display_name === 'BuyerBridge Ads') {
                    ownership = 'system';
                }
            });
            try {
                const data = {
                    agency_id: this.currentAgencyId,
                    q: this.searchTerm,
                    page_size: 9,
                    page: this.page,
                    order: {
                        field: dateField,
                        direction
                    },
                    tags: tagData,
                    filter_user_id: userId,
                    filter_agency_id: agencyId,
                    ownership
                };
                const response = await this.$http.post('ads/search', data);
                const responseAds = response.data.data;
                const filteredAds = responseAds.filter(ad => !this.duplicatedAdIds.includes(ad.ad_id));
                filteredAds.forEach(ad => {
                    let adSelected = this.selectedAds.find(selectedAd => selectedAd.id == ad.id);
                    if (adSelected) {
                        ad.selected = true;
                    } else {
                        ad.selected = false;
                    }
                });
                this.ads.push(...filteredAds);
                this.total = response.data.meta.total;
                this.page = response.data.meta.next_page;
            } catch (error) {
                console.error('Error loading creatives', error);
            } finally {
                this.loading = false;
            }
        },
        showPreview(currentAd) {
            this.currentAdId = currentAd.id;
            this.$router.push({
                name: 'ads-library-preview',
                params: {
                    ad_id: this.currentAdId,
                },
            });
        },
        deployAds() {
            const ads = this.selectedAds;
            this.$store.dispatch('adDeployment/start', {
                dealerId: this.dealer.id,
                userId: this.user.id,
                configuration: {
                    ads
                }
            });
        },
        editAd(ad) {
            const ads = [ad];
            this.$store.commit('adStudio/setAllAds', { ads });
            this.$store.commit('adStudio/setEditingExistingAd', true);
            EventBus.$emit('open-ad-studio', { editMode: true });
        },
        duplicateAd(ad) {
            const timestamp = format(new Date(), 'MM/DD/YY');
            const duplicatedAd = {
                agency_id: this.currentAgencyId,
                display_name: ad.display_name + ' - ' + timestamp,
                configuration: ad.configuration,
                dealer_id: ad.dealer_id,
                description: ad.description,
                full_configuration: ad.full_configuration ?? null,
                public: ad.public ?? true,
                thumbnail: ad.thumbnail,
                user_id: this.user.id,
            };
            this.createDuplicatedAd(duplicatedAd);
        },
        async createDuplicatedAd(data) {
            try {
                this.duplicatingLoading = true;
                const newAd = await this.$apiRepository.createAd(data);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                await sleep(600);
                this.duplicatedAdIds.push(newAd.data.data.id);
                this.ads.unshift(newAd.data.data);
            } catch (error) {
                console.log(error);
            } finally {
                this.duplicatingLoading = false;
            }
        },
        selectAll() {
            this.notice = false;
            this.selectedAds = [];
            this.ads.forEach(ad => {
                this.selectAd(ad.id);
            });
            if (this.total > this.selectedAds.length) {
                this.notice = true;
            }
            this.cardKey += 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.adslib{
    &__body{
        display: flex;
    }
    &__main{
        background: #F7F8FC;
        width: 80%;
        min-height: 68vh;
    }
    &__search-wrapper{
        padding: 15px 30px;
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__search{
        position: relative;
        min-width: 50%;
        padding-right: 4%;
        input{
            width: 100%;
            background: white;
            border: 1px solid #DEDEDE;
            border-radius: 5px;
            line-height: 38px;
            outline: none;
            padding-left: 55px;
        }
        &:before{
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            left:18px;
            top: calc(50% - 9px);
            background-image: url('/img/search.svg');
            pointer-events: none;
        }
    }
    &__sorting{
        display: flex;
        align-items: center;
        height: 37px;
    }
    &__select-sort{
        width: 110px;
    }
    &__count{
        border-right: 1px solid #D6DCDF;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    &__sort{
        display: flex;
        height: 100%;
        align-items: center;
        padding-left: 20px;
    }
    &__filtered{
        padding: 0 30px;
        border-bottom: 1px solid #D5DBDE;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__filters{
        padding-right: 45px;
    }
    &__selected{
        position: relative;
    }
    &__deploy{
        position: absolute;
        z-index: 4;
        background: white;
        padding: 15px;
        width: 428px;
        right:0;
        top: calc(100% + 10px);
        box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
        border-radius: 5px;
        &:after{
            content: '';
            position: absolute;
            width:20px;
            height: 20px;
            background: white;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
            top: -10px;
            right: 15px;
            transform: rotate(45deg);
            z-index: 99;
        }
        &:before{
            content: '';
            position: absolute;
            width:60px;
            height:20px;
            background: white;
            top: 0;
            right: 0;
            z-index: 100;
        }
    }
    &__complete{
        display: table;
        margin: 0 auto;
        text-align: center;
        color: white;
        font-weight: 700;
        cursor: pointer;
        font-size: 18px;
        padding:8px 45px;
        border-radius: 5px;
        background: #00A4EB;
    }
    &__button{
        display: inline-flex;
        align-items: center;
        padding:10px;
        padding-right: 20px;
        border-radius: 5px;
        background: #00A4EB;
        cursor: pointer;
        & > span{
            color:white;
            font-weight: bold;
        }
    }
    &__collapse{
        transform: rotate(180deg) translateY(-3px);
    }
    &__circle{
        background: white;
        color: #00A4EB;
        font-size: 20px;
        font-weight: 700;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-right: 15px;
    }
    &__item{
        background: #F7F8FC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 5px 10px;
        color: #00A4EB;
        font-size: 16px;
        margin-bottom: 15px;
    }
    &__mediaholder{
        display: flex;
        align-items: center;
    }
    &__media{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        img,video{
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__desc{
        overflow: hidden;
        max-width: 250px;
        white-space: nowrap;
    }
    &__close{
        display: flex;
        border-radius: 50%;
        background: #909FA8;
        padding: 5px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 15px;
    }
    &__title{
        color: #00A4EB;
        margin-bottom: 5px;
    }
    &__applied{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    &__single{
        display: flex;
        align-items: center;
        background: #D7F0FB;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        span{
            margin-right: 15px;
        }
        div{
            display: flex;
            align-items: center;
            margin-top:2px;
            cursor: pointer;
        }
    }
    &__clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
}

// FILTERS
.filters{
    width: 20%;
    padding: 15px 20px;
    &__top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    &__title{
        color: #00A4EB;
    }
    &__clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
    &__block{
        margin-bottom: 30px;
        padding-left: 20px;
    }
    &__checkbox{
        margin-top: 0;
        margin-bottom: 0;
    }
}
// transition animation
.cards-enter-active {
    transition: opacity 1s ease-in-out;
}
.cards-enter-to {
    opacity: 1;
}
.cards-enter {
    opacity: 0;
}
.select-all {
    color: #00A4EB;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}
.select-all-notice {
    background-color: $yellow-bg;
    border: 1px solid $yellow-dark;
    padding: 12px 20px;
    margin: 5px 40px 5px 30px;
    transform: translateY(15px);
}
</style>
