<template>
    <div
        class="card"
        :class="{active: actionsActive, checked: checked}"
        @mouseover="showActions"
        @mouseleave="hideActions">
        <div class="card__header">
            <span
                class="styled-checkbox-checkmark"
                @click="check(adData.id)" />
        </div>
        <div class="card__visual">
            <div
                v-if="thumbnail"
                class="card__image">
                <img
                    :src="thumbnail"
                    alt="">
            </div>
            <div
                v-else
                class="card__image">
                <img
                    v-if="adType === IMAGE_ASSET_TYPE"
                    :src="adUrl"
                    alt="">
                <video
                    v-if="adType === VIDEO_ASSET_TYPE"
                    ref="video"
                    muted
                    loop>
                    <source
                        :src="adUrl"
                        type="video/mp4">
                </video>
            </div>
            <div class="card__bg" />
            <div class="card__buttons">
                <div class="card-buttons-line">
                    <div class="card__button">
                        <action-button
                            icon="opportunity"
                            color="white"
                            size="30"
                            @click="deployCurrentAd(adData)">
                            Deploy
                        </action-button>
                    </div>
                    <div class="card__button">
                        <action-button
                            icon="eye"
                            color="white"
                            size="30"
                            @click="$emit('show-preview', adData)">
                            Preview
                        </action-button>
                    </div>
                </div>
                <div class="card-buttons-line">
                    <div
                        v-if="!hideEditButton"
                        class="card__button">
                        <action-button
                            icon="edit"
                            color="white"
                            size="30"
                            @click="$emit('edit-ad', adData)">
                            Edit
                        </action-button>
                    </div>
                    <div class="card__button">
                        <action-button
                            icon="copy"
                            color="white"
                            size="30"
                            :loading="duplicatingLoading"
                            :disabled="duplicatingLoading"
                            @click="$emit('duplicate-ad', adData)">
                            Duplicate
                        </action-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__info">
            <div
                ref="title"
                class="card__title">
                <styled-tooltip
                    v-if="titleTooltipActive"
                    position="top"
                    word-break
                    :open-delay="0"
                    :close-delay="0">
                    <template #content>
                        {{ adData.display_name }}
                    </template>
                    {{ title }}
                </styled-tooltip>
                <span v-else>
                    {{ title }}
                </span>
            </div>
            <author-name
                :author="adData.created_by_user_name"
                :author-id="adData.created_by_user_id" />
        </div>
        <div class="card__icons">
            <div
                v-if="bbIconIsVisible"
                class="card__holder">
                <icon
                    name="buyerbridge"
                    color="#8f9ea6"
                    size="20" />
            </div>
            <div class="card__holder">
                <span
                    v-for="(channel,index) in platforms"
                    :key="index">
                    <icon
                        :name="fbToMeta(channel.toLowerCase(), true)"
                        color="#8f9ea6"
                        size="16" />
                </span>
            </div>
            <div class="card__holder">
                <icon
                    :name="adType"
                    color="#8f9ea6"
                    size="20" />
            </div>
            <!--<div class="card__holder">
                <icon
                    name="cloud"
                    color="#8f9ea6"
                    size="20" />
            </div>-->
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import AuthorName from '@/components/globals/AuthorName';
import { mapState, mapGetters } from 'vuex';
import { BB_USER_ID } from '@/helpers/globals';
import cutTitleLength from '@/helpers/cutTitleLength';
import { VIDEO_ASSET_TYPE, IMAGE_ASSET_TYPE } from '@/components/ad-studio/store/constants';
import EventBus from '@/event-bus';
import { STATIC_DEPLOYMENT_TYPE } from '@/components/ad-deployment/store/constants';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        ActionButton,
        StyledTooltip,
        AuthorName,
        Icon
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        duplicatingLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            titleTooltipActive: false,
            actionsActive: false,
            checked: false,
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE,
            BB_USER_ID,
            fbToMeta
        };
    },
    computed: {
        ...mapGetters([
            'userIsAdmin'
        ]),
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
        }),
        adType() {
            return this.adData.full_configuration?.all_platforms?.creatives[0].assets[0].asset_type.name ?? this.adData.configuration?.all_platforms?.creatives[0].assets[0].asset_type.name;
        },
        adUrl() {
            return this.adData.full_configuration?.all_platforms?.creatives[0].assets[0].url ?? this.adData.configuration?.all_platforms?.creatives[0].assets[0].url;
        },
        thumbnail() {
            return this.adData.thumbnail;
        },
        platforms() {
            return this.adData.full_configuration?.selected_platforms ?? this.adData.configuration?.selected_platforms;
        },
        bbIconIsVisible() {
            return !this.agency.configuration?.has_white_label_access && this.adData.created_by_user_id === BB_USER_ID;

        },
        hideEditButton() {
            return !this.userIsAdmin && this.adData.created_by_user_id === BB_USER_ID;
        }
    },
    mounted() {
        this.checked = this.isSelected;
        this.title = this.adData.display_name;
        this.checkTitleHeight();
    },
    methods: {
        async checkTitleHeight() {
            await this.$nextTick();
            const titleResult = cutTitleLength(this.$refs.title.offsetHeight, this.title);
            if (!titleResult.ok) {
                this.titleTooltipActive = true;
                this.checkTitleHeight();
            }
            this.title = titleResult.title;
        },
        deployCurrentAd(adData) {
            const ads = [];
            ads.push(adData);

            EventBus.$emit('open-deployment-modal', {
                fromCreated: true,
                configuration: { ads },
                userId: this.user.id,
                dealerId: this.dealer.id,
                deployment_type: STATIC_DEPLOYMENT_TYPE
            });
        },
        showActions() {
            this.actionsActive = true;
            if (this.adType === VIDEO_ASSET_TYPE) {
                this.$refs.video.play();
            }
        },
        hideActions() {
            this.actionsActive = false;
            if (this.adType === VIDEO_ASSET_TYPE) {
                this.$refs.video.pause();
            }
        },
        check(id) {
            this.checked = !this.checked;
            if (this.checked) {
                this.$emit('select-ad', id);
            } else {
                this.$emit('remove-ad', id);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.card{
    width: 29%;
    background: white;
    border: 1px solid #D6DCDF;
    display: inline-block;
    margin-left: 3%;
    margin-top: 3%;
    &.active{
        .card__bg{
            opacity: 1;
        }
        .card__buttons{
            opacity: 1;
        }
    }
    &__header{
        height: 30px;
    }
    &__visual{
        //background: #8F9EA6;
        padding-bottom: 100%;
        position: relative;
    }
    &__image{
        position: absolute;
        top:0;
        right: 0;
        bottom:0;
        left:0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img, video{
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
        }
    }
    &__bg{
        opacity: 0;
        transition: 0.2s;
        position: absolute;
        top:0;
        right: 0;
        bottom:0;
        left:0;
        z-index: 2;
        background: rgba(0,0,0,0.6);
    }
    &__buttons{
        opacity: 0;
        transition: 0.2s;
        position: absolute;
        top:0;
        right: 0;
        bottom:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
    }
    &__button{
        margin: 0 40px;
    }
    &__info{
        padding: 10px 15px;
        border-bottom: 1px solid #D6DCDF;
        font-size: 12px;
        height: 90px;
    }
    &__title{
        color: #00A4EB;
        font-weight: 700;
        font-size: 14px;
        word-wrap: break-word;
        margin-bottom: 5px;
    }
    &__name{
        color: #00A4EB;
        text-decoration: underline;
    }
    &__icons{
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
    }
    &__holder{
        padding: 10px;
        display: flex;
        align-items: center;
        border-right: 1px solid #D6DCDF;
        svg{
            margin: 0 5px;
        }
        &:last-of-type{
            border-right: none;
        }
    }
    &.checked{
        outline: 4px solid #00A4EB;
        border: 1px solid #00A4EB;
        .styled-checkbox-checkmark {
            background-color: $blue-btn;
            border-color: $blue-btn;
            &::after {
                display: block;
            }
        }
    }
}
.card-buttons-line {
    display: flex;
    margin: 40px;
}
.styled-checkbox-checkmark {
    position: relative;
    display: block;
    top: 5px;
    left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    cursor: pointer;
    border: 2px solid;
    border-color: $gray;
    background-color: $white;
    transform-origin: center center;
    transform: scale(var(--checkmark-scale));
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    &:hover {
        background-color: $alabaster-dark;
    }
}
</style>

